// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import LogoWide from "../../../components/images/logowide"
import ShopifyLogo from "../../../components/images/shopify"
import GoToInstall from "../../../components/widgets/goToInstall"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyLanding: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="_compact">
                <ShopifyLogo />
                <h2>Facturación electrónica en Shopify</h2>
                <p className="intro">
                    Nuestra app para shopify está diseñada para simplificarte el trabajo, te permite facturar desde el Admin y en línea
                    con mínimas configuraciónes. Las facturas se elaboran a partir de las órdenes de venta, ya sea venta normal con
                    productos en inventario o ventas rápidas (Quick Sales).
                </p>
                <h3>
                    Funcionalidades
                </h3>
                <ul>
                    <li>
                        <p>
                            <b>Facturación de ventas rápidas:</b> Podrás facturar las ventas rápidas (Quick Sales) elaboradas desde el POS o tu Admin aunque no
                            aparezcan en tu inventario.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Facturación de orden sencilla:</b> Podrás facturar cada orden independiente y configurarla según sea necesario. Los valores de ésta
                            se pre-cargan de la información de tu tienda.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Facturación masiva:</b> Podrás seleccionar todas las órdenes que no se hayan facturado y editarlas en bonche, ordenándolas por
                            receptor y uso, facturando con un solo click. <br />
                            <span className="small">Nota: La facturación masiva agrupa varias órdenes en una sola factura, por lo que se te cobraría una sola factura inclusive si ésta se constituyera de 20
                            órdenes o más.
                        </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Facturación global:</b>  (facturas mostrador no reclamadas) Usando nuestra facturación masiva puedes realizar las facturas globales
                            de las órdenes que no fueron reclamadas por el receptor. <br />
                            <span className="small">Nota: La funcionalidad es la misma que facturación masiva, solo deben ser emitidas a "PUBLICO EN GENERAL". </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Facturación en línea:</b>  Podrás agregar una página o sección en tu tienda para que clientes puedan facturar automáticamente. Solo
                            necesitarán el #orden y el monto total aproximado (no exacto), no tendrás que hacer nada, nosotros nos encargamos de emitir la
                            factura y registrar la orden como facturada en la app de tu tienda. <br />
                            <span className="small quote"> Conoce como agregar tu página de facturación en línea <Link to="./pagina-facturacion">aquí</Link></span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Facturación en Check-out:</b> Cuando los clientes realicen una compra en tu tienda en línea, podrán solicitar su factura
                                inmediatamente en la página de agradecimiento. De lo contrario podrán solicitarla en tu página de facturación en línea si está
                                activada (punto anterior).<br />
                            <span className="small quote"> Conoce como funciona facturación en Check-out <Link to="./facturacion-checkout">aquí</Link></span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Configuración de impuestos automática:</b>  Debido a que Shopify ya cuenta con la opción de agregar o quitar impuestos a un
                            producto, no tendrás que realizar esta configuración tu mismo. Todo producto que cobre impuestos en Shopify tendrá su IVA
                            configurado con nosotros <br />
                            <span className="small quote"> Conoce más acerca de configurar tus impuestos <Link to="./tus-impuestos">aquí</Link></span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Descuentos correctamente asignados: </b> Seguimos el mismo proceso de Shopify para agregar los descuentos a tu factura, ya sean
                            por producto o por órden completa. <br />
                            Los descuentos por producto se mantienen tal cual, los descuentos por orden se distribuyen entre todos los productos al
                            momento de Facturar. <br />
                            <span className="small quote"> Conoce más acerca de configurar tus impuestos <Link to="./tus-impuestos">aquí</Link></span>
                        </p>
                    </li>
                </ul>
                <GoToInstall href="" />
                <div className="sectionNote">
                    <p>
                        Tus cerificados están seguros con nosotros ya que <b>no guardamos</b> estos archivos, solo obtenemos la
información que requerimos, la encriptamos y posteriormente los descartamos.
                    </p>
                </div>
            </div>
        </div>
    </Layout>
)

export default ShopifyLanding
